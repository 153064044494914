import React from 'react'
import Intro from '../../../components/Intro';
import Section from '../../../components/Section';
import ImgSemifull from '../../../components/ImgSemifull';
import Back from '../../../components/Back';
import ImgSmall from '../../../components/ImgSmall';
import Pagination from '../../../components/Pagination';
import Title1 from '../../../components/Title1';
import Helmet from 'react-helmet';
import Logo from './logo-race.svg';
import Pages from './pages.svg';
import Map from './map.svg';
import styles from '../../../styles/styles.json';
import Layout from "../../../components/layoutProject"
import SEO from "../../../components/seo"

export const frontmatter = {
  id: 3,
  isWork: true,
  url: 'race',
  title: 'RACE',
  subtitle: 'Identity Recognition Platform',
  path: '/work/race',
  excerpt: `Identity Recognition Platform`,
};

const RacePage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Back />
      <div className="notch">
        <Helmet title={`${frontmatter.title} | ${data.site.siteMetadata.title}`} />
        <Intro dark full bg={styles.colors.race.base} >
          <img className="brand" src={Logo} alt={frontmatter.title} />
          <ImgSmall img={Pages} />
          <p className='highlight'>The redesign of the biggest Automobile Club of Spain.</p>
          <p>This project was an ambitious digital exercise to redesign the web platform, the mobile application and facilitate the creation of marketing campaigns.</p>
        </Intro>

        <Section bg={styles.colors.white.base}>
          <Title1 title='Digital transformation' />
          <p>RACE asked us to redesign their main website and most of their satellite sites and mobile applications. We achieved it in multiple stages.</p>
          <p>We interviewed each manager of the different areas of the company, and we eventually presented the new strategy to the client.</p>
          <ImgSemifull img={Map} alt='Timeline' />
          <p>The content had become challenging to manage and share. We propose to improve it by:</p>
          <ol>
            <li><strong>Using templates:</strong> Detect common patterns and unify all content in a new CMS.</li>
            <li><strong>Promoting dynamic content:</strong> The information should be indexable and shared across teams.</li>
            <li><strong>Unifying styles:</strong> Reinforce the brand guidelines reusing the same visual identity for the different sites.</li>
          </ol>
        </Section>

        <Section dark full bg={styles.colors.race.base}>
          <Title1 title='Multisite' />
          <p>I was mainly involved in all the previous work of consultancy and design definition. The project was executed in different stages, having in mind the new needs of content sharing and reusability.</p>
          <ImgSemifull img={data.fundationOne.childImageSharp.fluid.src} alt='Nirovision symbol' />
          <ImgSemifull img={data.fundationTwo.childImageSharp.fluid.src} alt='Nirovision symbol' />
          <p>This project required multiple profiles to cover all areas. The biggest challenge was to understand how the company internally works to provide the right tooling.</p>
        </Section>

        <Section bg='#fff'>
          <Title1 title='Adaptive content' />
          <p>The information should be completely detached from design. The team worked on the editorial content, and we could make it reusable in any format.</p>
          <ImgSemifull img={data.chair.childImageSharp.fluid.src} alt='Nirovision symbol' style="borderbright" />
        </Section>

      </div>
      <Pagination currentproject={frontmatter.id} mydata={data} />
    </Layout>
  )
};

export default RacePage;

export const query = graphql`
  query GatsbyRaceQuery {
    site {
      siteMetadata {
        title
      }
    }

    allJavascriptFrontmatter(filter: {frontmatter: {path: {regex: "/work/"}}}, sort: {fields: [frontmatter___id], order: ASC}) {
      edges {
        node {
          frontmatter {
            isWork
            path
            id
            title
            url
          }
        }
      }
    }

    fundationOne: file(relativePath: { eq: "work/race/fundation-one.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
    fundationTwo: file(relativePath: { eq: "work/race/fundation-two.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
    chair: file(relativePath: { eq: "work/race/banner-chair.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2600) {
          src
        }
      }
    }
  
  }
`;